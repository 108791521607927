import { Component, OnDestroy } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { MensajesService } from './services/mensajes.service';
import { Router } from '@angular/router';
import { UsuariosService } from './services/usuarios.service';
import { Subscription } from 'rxjs';
import { Usuario } from './models/usuario';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  usuario: Usuario = null;
  userMenu = [];
  subsUsuario: Subscription;
  constructor(
    private menu: MenuController,
    private authService: AuthService,
    private msjs: MensajesService,
    private router: Router,
    private usuariosService: UsuariosService
  ) {
    this.cargarMenu();
  }

  cerrarMenu() {
    this.menu.close();
  }

  cargarMenu() {
    this.authService.afAuth.onAuthStateChanged((usuarioActual) => {
      if (usuarioActual) {
        const usuarioActual$ = this.usuariosService.obtenerUsuario(usuarioActual.uid);
        this.subsUsuario = usuarioActual$.subscribe(usuario => {
          this.usuario = usuario;
          if (this.usuario.perfil) {
            this.menu.enable(true);
            switch (this.usuario.perfil) {

              case 'Administrador':
                this.userMenu = [
                  {
                    icono: 'fa-solid fa-user-gear',
                    funcion: 'Administrar Usuarios',
                    redireccionar: '/users',
                  },
                  {
                    icono: 'fa-solid fa-scale-balanced',
                    funcion: 'Editar Status',
                    redireccionar: '/editar-status',
                  },
                ];
                break;

              case 'Performance Price':
                this.userMenu = [
                  {
                    icono: 'fa-solid fa-scale-balanced',
                    funcion: 'Editar Status',
                    redireccionar: '/editar-status',
                  },
                ];
                break;
            }

            this.subsUsuario.unsubscribe();
          }
        });

      }
    }).then();
  }

  async cerrarSesion() {

    await this.authService.logout().then(
      () => {
        this.menu.enable(false);
        this.msjs.mensajeExito(
          'Sesión Finalizada',
          `Hemos cerrado tu sesión, hasta pronto ${this.usuario.nombre}.`
        );
        this.usuario = null;
        this.router.navigate(['/login']);
      }
    );

  }
}
