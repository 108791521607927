// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const ipApiExpuesta = 'https://api-pricing-desarrollo.dbs.cl:5000/api/v1';

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyC2XhxayyPbz9pGVN6BJRVcb684bX6M9_g',
    authDomain: 'dbs-pricing-staging.firebaseapp.com',
    projectId: 'dbs-pricing-staging',
    storageBucket: 'dbs-pricing-staging.appspot.com',
    messagingSenderId: '519229224984',
    appId: '1:519229224984:web:6f6a2472c859d6e6a3eb6d',
    measurementId: 'G-XX39D9QW4E'
  },
  ipApiBackend: ipApiExpuesta
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
