import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Log } from '../models/log';
import { MensajesService } from './mensajes.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  private logsRef = this.afs.collection<Log>('Logs');
  private url = 'Logs';

  constructor(
    public afs: AngularFirestore,
    public msjs: MensajesService
  ) { }

  async agregarLog(logStatus: string, descripcion: string) {
    const log: Log = new Log();
    log.id = this.afs.createId();
    log.fecha = new Date().toLocaleString('es-CL');
    log.status = logStatus;
    log.mensaje = descripcion;

    try {
      return await this.logsRef.doc<Log>(log.id).set(Object.assign({}, log));
    } catch (error) {
      console.log('Error Log:', error);

      this.msjs.mensajeError(
        'Ha ocurrido un error',
        'No hemos podido registrar el error, por favor notifica al Administador.'
      );
    }
  }

  actualizarLog(log: Log) {
    const ref = this.afs.collection<Log>(this.url);
    return ref.doc(log.id).update(Object.assign({}, log));
  }

  obtenerLog(id: string) {
    const ref = this.afs.collection<Log>(this.url);
    return ref.doc(id).valueChanges();
  }


  listarLogs(tipoRegistro: string) {
    return this.afs.collection<Log>(
      this.url, ref => ref.where('tipoRegistro', '==', tipoRegistro)).valueChanges();
  }

}
