import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Usuario } from '../models/usuario';
import { MensajesService } from './mensajes.service';
import { Log } from '../models/log';
import { LogsService } from './logs.service';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private url = 'Usuarios';
  private ref = this.afs.collection<Usuario>(this.url);
  constructor(
    public afs: AngularFirestore,
    private msjs: MensajesService,
    private logService: LogsService
  ) { }

  async crearUsuario(usuario: Usuario) {
    try {
      return await this.ref.doc(usuario.idFirebase).set(Object.assign({}, usuario));
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          this.msjs.mensajeError(
            'Email existente',
            'El email ingresado se encuentra registrado.'
          );
          break;

        default:
          this.msjs.mensajeError(
            'Ha ocurrido un error',
            'No hemos logrado registrar el usuario en el sistema.'
          );
          break;
      }

      await this.logService.agregarLog('Error de firebase', error.message).then();
    }
  }

  actualizarUsuario(usuario: Usuario) {
    return this.ref.doc(usuario.idFirebase).update(Object.assign({}, usuario));
  }

  obtenerUsuario(idUsuario: string) {
    return this.ref.doc(idUsuario).valueChanges();
  }

  eliminarUsuario(idUsuario: string) {
    return this.ref.doc(idUsuario).delete();
  }

  listarUsuarios() {
    return this.afs.collection<Usuario>(this.url).valueChanges();
  }

  async actualizarAvatarUsuario(idUsuario: string, avatarUrl: string) {
    const ref = this.afs.collection(this.url).doc(idUsuario);
    try {
      await ref.set({ avatar: avatarUrl }, { merge: true });
      console.log(`Se actualizo avatar ${avatarUrl}`);
      console.log('La avatar del usuario ha sido actualizado');
    } catch (err) {
      console.error(`Ha ocurrido un error, no hemos podido actualizar el avatar \n ${err}`);
    }
  }

}
